<template>
  <div>
    <article>
      <h3 class="center">{{ appName }}App隐私政策摘要</h3>
      <section>
        <p>
          <b
            >{{
              companyName
            }}及关联公司深知个人信息对您的重要性，我们将按照法律法规规定并参照行业最佳实践为您的个人信息及隐私安全提供充分保障。为此，我们希望通过本文向您简洁介绍我们如何通过“{{
              appName
            }}”平台收集、使用和保护您的个人信息。如您希望了解我们详细的隐私保护指引，请查阅<a :href="privacyUrl"
              >《{{ appName }}App隐私政策》</a
            >正文。</b
          >
        </p>
      </section>
      <section>
        <p><b>1、 为提供服务收集使用的个人信息</b></p>
        <div class="table">
          <div class="t-head">
            <ul>
              <li v-for="(item, index) in option.column" :key="index">{{ item.label }}</li>
            </ul>
          </div>
          <div class="t-body" v-for="(item, index) in tableData" :key="index">
            <ul>
              <li v-for="(context, i) in option.column" :key="i">
                <span v-if="context.prop !== 'Links'">{{ item[context.prop] }}</span>
                <span v-else class="align-start"
                  ><a :href="item[context.prop]" v-if="typeof item[context.prop] === 'string'">{{
                    item[context.prop]
                  }}</a>
                  <a :href="link" v-for="link in item[context.prop]" v-else>{{ link }} <br /></a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section>
        <p>
          <b>2、 为提供服务申请使用的权限</b>
        </p>
        <p>
          为向您提供内容播放、头像上传、评论或分享、文件上传下载、图像识别、同城作品推荐、清唱或跟唱、签到提醒、一键登录、日历提醒、创新广告交互功能、蓝牙连接等功能，我们可能会申请使用您的以下权限:读写/读取外部存储、相册、相机、位置、麦克风、剪切板、读取修改日历、读取电话号码、访问账号列表、传感器、蓝牙、关联启动、自启动等权限。我们申请使用相关权限的目的请详见<a
            :href="permissionUrl"
            >《快音App客户端使用的权限说明》</a
          >。
        </p>
      </section>
      <section>
        <p>
          <b>3、 与第三方共享的个人信息</b>
        </p>
        <p>
          为保障快音App的稳定运行以及实现包括但不限于第三方账号登录、消息推送、运营统计、安全风控、第三方分享等的相关功能，您的个人信息可能与第三方进行共享。我们将按照相关法律法规要求并参照行业最佳实践不断完善和提升对您个人信息的安全保障水平。我们严格遵循隐私政策相关约定，一旦涉及共享您的个人敏感信息或新增共享方，我们将再次征求您的授权同意。您可查阅<a
            :href="SDKUrl"
            >《快音App第三方信息共享清单》</a
          >来了解具体的共享情况。
        </p>
      </section>
      <section>
        <p>
          <b>4、 用户权益</b>
        </p>
        <div class="table">
          <div class="t-head">
            <ul>
              <li v-for="(item, index) in option1.column" :key="index">{{ item.label }}</li>
            </ul>
          </div>
          <div class="t-body" v-for="(item, index) in tableData1" :key="index">
            <ul>
              <li v-for="(context, i) in option1.column" :key="i">
                <span v-if="context.prop !== 'Links'">{{ item[context.prop] }}</span>
                <span v-else class="align-start">
                  <template v-if="typeof item[context.prop] === 'string'">
                    <a :href="item[context.prop]">{{ item[context.prop] }}</a>
                  </template>
                  <template v-else>
                    <a v-for="(link, index) in item[context.prop]" :href="link" :key="index"> {{ link }}<br /> </a>
                  </template>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'UserReleaseCommitment',
    components: {},
    props: {},
    data() {
      return {
        privacyUrl: 'https://landing.happyky.cn/privacy-protocol',
        permissionUrl: 'https://landing.happyky.cn/permission-description',
        SDKUrl: 'https://landing.happyky.cn/sdk-list',
        tableData: [
          {
            Feature: '用户注册',
            PersonalType: '头像、昵称、性别',
            Treatment: '用户主动提供',
          },
          {
            Feature: '现金提现',
            PersonalType: '手机号码、支付号账号、姓名',
            Treatment: '用户主动提供',
          },
          {
            Feature: '个性化推荐',
            PersonalType:
              '性别、年龄、地区、点击次数、关注对象、收藏内容、搜索内容、浏览内容、分享内容、评论内容、点赞（喜欢）发布内容及次数、互动交流、反馈浏览使用行为、浏览和播放时长、本地音频文件信息、提现金额、设备标识信息、设备厂商和型号、系统版本。',
            Treatment: '用户主动提供、本机收集',
          },
          {
            Feature: '产品安全提供及安全保障',
            PersonalType:
              '设备信息、日志信息，包括设备品牌及型号、设备屏幕规格、操作系统版本、屏幕分辨率、浏览器类型、电信运营商、使用语言、硬件序列号、IP地址、MEID、Android ID、蓝牙地址、WLAN接入点（BSSID、SSID）、OAID、IMSI、IMEI、ICCID、IDFA、IDFV、GAID、Open UDID、用户使用终端设备访问本平台时的设备传感器数据、访问日期和时间信息、服务日志、DHCP。',
            Treatment: '本机收集',
          },
          {
            Feature: '风控安全保障',
            PersonalType: 'IMSI、设备MAC地址、IMEI、MEID、Android ID、OAID、IP地址、运行中进程信息。',
            Treatment: '本机收集',
          },
          {
            Feature: '广告投放',
            PersonalType:
              '设备标识符，广告数据，设备信息（如设备型号、系统语言、屏幕高宽、屏幕方向、MAC地址），应用及使用数据（如应用包名以及浏览、点击、关注、搜索、分享、收藏操作日志），连接的WIFI，粗略地理位置信息，access_coarse_location信息。',
            Treatment: '本机收集',
          },
          {
            Feature: '预防恶意程序、提升运营安全及效率',
            PersonalType:
              '已安装的应用列表、安装的应用信息或正在运行的进程信息、应用程序的总体运行情况、总体安装使用情况、性能数据、应用来源。',
            Treatment: '本机收集',
          },
        ],
        option: {
          column: [
            {
              label: '业务功能',
              prop: 'Feature',
            },
            {
              label: '个人信息类型',
              prop: 'PersonalType',
            },
            {
              label: '收集方式',
              prop: 'Treatment',
            },
          ],
        },
        option1: {
          column: [
            {
              label: '用户权益',
              prop: 'UserRights',
            },
            {
              label: '相关路径',
              prop: 'Path',
            },
          ],
        },
        tableData1: [
          {
            UserRights: '管理个性化推荐',
            Path: '如您不希望接受个性化推荐内容，可以通过“我的”-“设置”-“个性化推荐”，选择管理个性化内容推荐。',
          },
          {
            UserRights: '其他',
            Path: '查阅复制、删除、更正、注销、撤回同意、投诉举报等用户权益实现，请查阅《快音APP隐私政策》。',
          },
        ],
        appName: '',
        appNameMap: new Map([
          ['kuaiyin', { appName: '快音', companyName: '成都开心音符科技有限公司', companyAlias: '开心音符公司' }],
          [
            'kuaiyintingge',
            { appName: '快音听歌版', companyName: '成都超开心信息科技有限公司', companyAlias: '超开心公司' },
          ],
          [
            'kuaiyinjisu',
            { appName: '快音极速版', companyName: '成都超开心信息科技有限公司', companyAlias: '超开心公司' },
          ],
          ['kuaiyinyue', { appName: '快音悦', companyName: '成都开心音符科技有限公司', companyAlias: '开心音符公司' }],
          ['kuaiyinfm', { appName: '快音FM', companyName: '成都开心音符科技有限公司', companyAlias: '开心音符公司' }],
        ]),
        companyAlias: '',
        companyName: '',
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      let app_name = 'kuaiyin';
      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      this.privacyUrl += `?app_name=${app_name}`;
      this.permissionUrl += `?app_name=${app_name}`;
      this.SDKUrl += `?app_name=${app_name}`;
      this.appName = this.appNameMap.get(app_name).appName;
      this.companyName = this.appNameMap.get(app_name).companyName;
      this.companyAlias = this.appNameMap.get(app_name).companyAlias;

      document.title = `${this.appName}App隐私政策摘要`;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }
  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
    text-indent: 2em;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  .align-start {
    text-align: start;
  }
  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .t-head ul li {
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
  }
</style>
